<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Capture Profiles</h1>
      <div class="btns-container">
        <VasionButton
          v-if="isDataAvailable"
          class="last-btn"
          classProp="primary"
          @vasionButtonClicked="createNewClicked()"
        >
          New Capture Profile
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div v-if="whiteScreen === true">
      <!-- Show white space while waiting for data to load -->
      </div>
      <div v-else-if="isDataAvailable === true && tableData.Columns" class="main-table-container vasion-html-table-default">
        <VasionTable
          :headerColumns="tableData.Columns.Values"
          :tableRows="tableData.Rows.Values"
          :hideColumns="hiddenColumns"
          :filterByColumnDefault="1"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          @vasion-selection="rowClickHandler"
        />
      </div>
      <div v-else class="no-items-div">
        <div class="vasion-page-subheader instructional-message-div">
          No data to show.
        </div>
        <div class="button-div">
          <VasionButton :isFullWidth="true" :classProp="'primary'" @vasionButtonClicked="createNewClicked()">
            <span class="vasion-button-plus"> + </span> ADD NEW
          </VasionButton>
        </div>
      </div>
    </div>
    <md-dialog id="vasion-confirmation-div" :md-active.sync="showDeleteDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog :message="deleteMessage" @noButtonClick="showDeleteDialog = !showDeleteDialog" @yesButtonClick="deleteCaptureProfile()" />
    </md-dialog>
    <VasionSnackbar
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'CaptureProfiles',
  components: {
    Loading,
  },
  data: function () {
    return {
      columns: {
        Values: [
          '_iID_',
          'Name',
          '_VasionEllipsisButton_',
        ],
        ValueType: 'String',
      },
      deleteProfileId: '',
      deleteProfileName: '',
      deleteMessage: '',
      hiddenColumns: ['_iID_'],
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      profilesList: [],
      showDeleteDialog: false,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      tableData: {},
      whiteScreen: true,
    }
  },
  computed: {
    canConfigureCaptureProfiles() { return this.$store.getters['common/canConfigureCaptureProfiles'] },
    ellipsisButtonConfig() {
      let buttons = [
          {
            id: 'start-scan',
            name: 'Start Scan',
            icon: 'VasionStartScanIcon',
          },
          {
            id: 'set-as-default',
            name: 'Set As Default',
            icon: 'VasionSetDefaultIcon',
          },
        ] 
      if (this.canConfigureCaptureProfiles) {
        buttons.push (
            {
              id: 'edit-capture-profiles-button',
              name: 'Edit',
              icon: 'VasionEditIcon',
            },
            {
              id: 'delete-capture-profiles-button',
              name: 'Delete',
              icon: 'VasionDeleteIcon',
            },
        )
      }
      return {
        buttons : buttons
      }
    },
    isDataAvailable() { return this.profilesList && this.profilesList.length > 0 },
  },
  async created() {
    this.isLoading = true
    this.profilesList = await this.$store.dispatch('capture/getCaptureProfiles')
    this.whiteScreen = false

    const data = {
      list: this.profilesList,
      columns: this.columns,
      type: 'CaptureProfiles',
    }
    this.tableData = await this.$store.dispatch('capture/setTableData', data)
    this.isLoading = false
    this.updateTableData()
  },
  methods: {
    createNewClicked() { this.$router.push({ name: 'EditCaptureProfile' }) },
    async deleteCaptureProfile() {
      this.isLoading = true
      const response = await this.$store.dispatch('capture/deleteCaptureProfile', this.deleteProfileId)
      this.updateTableData()
      this.showDeleteDialog = false

      this.snackbarTitle = response ? `"${this.deleteProfileName}" Deleted Successfully` : 'Error'
      this.snackbarSubTitle = response ? 'The Capture Profile has been removed' : 'Capture Profile not deleted'
      this.snackbarImage = response
      this.showSnackbarBool = true;
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        const [id, name] = payload.item.Values
        switch (payload.buttonId) {
          case 'edit-capture-profiles-button':
            this.$router.push({ name: 'EditCaptureProfile', params: { captureProfileId: id } })
            break;
          case 'delete-capture-profiles-button':
            this.deleteMessage = `Are you sure you want to delete "${name}"?`
            this.deleteProfileId = id
            this.deleteProfileName = name
            this.showDeleteDialog = true
            break;
          case 'start-scan':
            this.startScan(id)
            break;
          default:
            break;
        }
      }
    },
    rowClickHandler(data) {
      if (!data || !data.Values || data.Values.length <= 0) return
      this.$router.push({ name: 'EditCaptureProfile', params: { captureProfileId: data.Values[0] } })
    },
    async startScan(id) {
      this.isLoading = true
      const payload = {
        captureProfileID: id,
      }
      await this.$store.dispatch('common/openScanToVasion', payload)
      this.isLoading = false
    },
    async updateTableData() {
      this.isLoading = true
      this.profilesList = await this.$store.dispatch('capture/getCaptureProfiles')
      this.whiteScreen = false

      const data = {
        list: this.profilesList,
        columns: this.columns,
        type: 'CaptureProfiles',
      }
      this.tableData = await this.$store.dispatch('capture/setTableData', data)
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.button-div {
  margin: auto;
  margin-top: 30px;
  width: 288px;
}

.inner-div {
  padding-top: 8px;
  margin-right: 8px;
  margin-left: 16px;
  text-align: center;
}

.instructional-message-div {
  margin-top: 24px;
}

.grid-div {
  height: calc(100vh - 224px);
  overflow: auto;
  text-align: left;
}
</style>
